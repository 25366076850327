import "./App.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./authentication/Login";
import Signup from "./authentication/Signup";
import Dashboard from "./dashboard/Dashboard";
import AuthGuard from "./auth-guard";
import Box from "@mui/material/Box";
import AppHeader from "./layout/AppHeader";
import Home from "./layout/Home";

const drawerWidth = 240;


function setToken(userToken) {
  sessionStorage.setItem("TOKEN", userToken.access_token);
  sessionStorage.setItem("EXPIRES_IN", userToken.expires_in);
  sessionStorage.setItem("TYPE", userToken.token_type);
}

function getToken() {
  const tokenString = sessionStorage.getItem("token");
  const userToken = JSON.parse(tokenString);
  return userToken?.token;
}

function removeToken() {
  sessionStorage.removeItem();
}

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    let isAuthenticated = false;
    return (
      <BrowserRouter>
      <AppHeader></AppHeader>
        <div className="App">
          <Routes className="app-container">
            <Route exact path="login" element={<Login setToken={setToken} />} />
            <Route path="signup" element={<Signup />} />
            <Route path="/" element={<Home/>} />
            <Route exact path="/" element={<AuthGuard auth={true} />}>
              <Route path="dashboard" element={<Dashboard />}></Route>
            </Route>
          </Routes>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
