import { environment } from '../environment';

export async function signup(data) {
    const response = await fetch(environment.apiUrl + "signup", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
    if (response.status === 200) {
        return await response.json();
    }else{
        throw new Error(await response.json());
    }
}

export async function login(data) {
    const response = await fetch(environment.apiUrl + "login", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
    if (response.status === 200) {
        return await response.json();
    }else{
        throw new Error(await response.json());
    }
}