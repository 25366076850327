import React from 'react';

class Dashboard extends React.Component{
    render() {
        return(
            <div>
                <h2>Dashboard</h2>
            </div>
        );
    }
}

export default Dashboard;