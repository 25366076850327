import React from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import { Navigate } from "react-router-dom";
import { login } from "../services/authentication-service";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      user: null,
      error: null,
      showPassword: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleClickShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  handleMouseDownPassword(event) {
    event.preventDefault();
  }

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    login(this.state)
      .then((res) => {
        let user = res.user;
        this.props.setToken(res.token);
        this.setState({ user });
      })
      .catch((err) => {
        let error = err;
        this.setState({ error });
      });
  }

  render() {
    let { user, error } = this.state;
    return (
      <div className="page-load">
        {error && <p>{error.message}</p>}
        {user && <Navigate to="/dashboard" replace={true} />}
        {/* <div className="login-form"> */}
          
          <Stack
            spacing={1}
            sx={{ m: 1, width: "35ch" }}
            component="form"
            onSubmit={this.handleSubmit}
            noValidate
            autoComplete="off"
          >
            <h2 className="text-center">Login</h2>
            <TextField
              required
              id="outlined-required"
              label="Email"
              name="email"
              placeholder="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
            <FormControl variant="outlined">
              <InputLabel htmlFor="password">Password</InputLabel>
              <OutlinedInput
                id="password"
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.password}
                name="password"
                onChange={this.handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                      edge="end"
                    >
                      {this.state.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <Button variant="contained" type="submit">
              Login
            </Button>
          </Stack>
        {/* </div> */}
      </div>
    );
  }
}

export default Login;
