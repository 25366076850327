import "../App.css";
export default function Home(props) {
  return (
    <div className="page-load">
      <h1 className="home-title">ABK Development</h1>
      <div className="home-container">
        <img
          className="home-img"
          srcSet={require("../asset/19362653.jpg")}
        ></img>
      </div>
    </div>
  );
}
