import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import { Navigate } from "react-router-dom";
import { signup } from '../services/authentication-service';

class Signup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
            showPassword: false,
            showConfirmPassword: false,
            user: null,
            error: null
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
        this.handleClickShowConfirmPassword = this.handleClickShowConfirmPassword.bind(this);
        this.handleMouseDownConfirmPassword = this.handleMouseDownConfirmPassword.bind(this);
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    handleClickShowPassword() {
        this.setState({
            showPassword: !this.state.showPassword,
        });
    };

    handleMouseDownPassword(event) {
        event.preventDefault();
    };

    handleClickShowConfirmPassword() {
        this.setState({
            showConfirmPassword: !this.state.showConfirmPassword,
        });
    };

    handleMouseDownConfirmPassword(event) {
        event.preventDefault();
    };

    handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ [name]: value });
    }

    handleSubmit(event) {
        event.preventDefault();
        
        signup(this.state)
            .then(
                res => {
                    let user = res.user;
                    this.setState({ user });
                }
            ).catch(
                err => {
                    let error = err;
                    this.setState({ error });
                    console.log(err);
                }
            )
    }

    render() {
        let { user, error } = this.state;
        return (
            <div className='page-load'>
                {error && <p>{error.message}</p>}
                {user && (
                    <Navigate to="/dashboard" replace={true} />
                )}
                <h2>Signup</h2>
                <Stack spacing={1}
                    sx={{ m: 1, width: '35ch' }}
                    component="form"
                    onSubmit={this.handleSubmit}
                    noValidate
                    autoComplete="off">
                    <TextField
                        id="name"
                        label="Name"
                        variant="outlined"
                        name='name'
                        placeholder='Enter your name'
                        value={this.state.name}
                        onChange={this.handleChange} />
                    <TextField
                        id="email"
                        label="Email"
                        variant="outlined"
                        name='email'
                        placeholder='Enter your email'
                        value={this.state.email}
                        onChange={this.handleChange} />
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <OutlinedInput
                            id="password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            value={this.state.password}
                            name="password"
                            onChange={this.handleChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.handleClickShowPassword}
                                        onMouseDown={this.handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="confirm_password">Confirm Password</InputLabel>
                        <OutlinedInput
                            id="confirm_password"
                            type={this.state.showConfirmPassword ? 'text' : 'password'}
                            value={this.state.password_confirmation}
                            name="password_confirmation"
                            onChange={this.handleChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.handleClickShowConfirmPassword}
                                        onMouseDown={this.handleMouseDownConfirmPassword}
                                        edge="end"
                                    >
                                        {this.state.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Confirm Password"
                        />
                    </FormControl>
                    <Button variant="contained" type="submit">Sign Up</Button>
                </Stack>
            </div>
        )
    }
}

export default Signup;